.footer {
    position: relative; /* Use relative positioning for responsiveness */
    bottom: 0;
    width: 100%;
    background-color: #f1f1f1; /* Optional: Add a background color */
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box; /* Ensure padding is included in the total width */
    left: 0;
  }

  /* Ensure the body takes full height and has a minimum height */
  html, body {
    height: 100%;
    margin: 0;
  }
  
  /* Ensure the main content takes full height minus footer height */
  .main-content {
    min-height: calc(100vh - 60px); /* Adjust 60px to match your footer height */
    box-sizing: border-box; /* Ensure padding and borders are included in the height */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .footer {
      padding: 15px 0; /* Increase padding on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding: 20px 0; /* Further increase padding for very small screens */
    }
  
    .footer p {
      font-size: 14px; /* Adjust font size on very small screens */
    }
  }
  