/* admin.module.css */

.container {
    padding: 20px;
    max-width: 900px;
    margin: 150px auto;
    font-family: 'Arial', sans-serif;
  }
  
  .profile-header, .folio-header {
    border-bottom: 1px solid #D32F2F;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .profile-header h2, .folio-header h2 {
    margin: 0;
    color: #D32F2F;
    text-align: left;
    padding-left: 20px;
  }
  
  .profile-details {
    margin-bottom: 30px;
    padding-left: 20px; /* Agregar padding para espaciar */
    position: relative; /* Para posicionar el icono */
  }
  
  .profile-details p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .folios-section {
    margin: 20px 0 70px;
  }
  
  .folio-card {
    padding-left: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #333;
  }
  
  .folio-card h3:first-of-type {
    font-size: medium;
    margin-top: 0;
    color: #D32F2F;
    font-weight: bold;
  }

  .folio-card h3:nth-of-type(2) {
    font-size: medium;
    margin-top: 0;
    color: #D32F2F;
    font-weight: bold;
  }
  
  .folio-card p {
    font-size: 14px;
    line-height: .7;
    color: #333;
  }

.opportunitiesContainer p {
    line-height: 1;
  }

  .ticketsContainer {
    display: flex;
    flex-wrap: wrap; /* Allows tickets to wrap to the next line */
    gap: 5px; /* Adds space between each ticket box */
    margin-bottom: 10px;
  }

  .boleto {
    background-color: #DAA520;
    padding: 10px;
    border-radius: 8px;
    width: 60px; /* Ajuste del ancho */
    height: auto; /* Auto ajustar la altura */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    margin: 5px;
    font-size: 14px;
  }

  .noFoliosMessage {
    padding-left: 20px;
  }

  .button-container {
    margin-top: 15px;
  }
  
  .button-container button {
    background-color: #D32F2F;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .button-container button:hover {
    background-color: #9b2017;
  }
  
  .not-paid-button {
    background-color: #f44336 !important;
  }
  
  .not-paid-button:hover {
    background-color: #e63946 !important;
  }
  
  .no-folios-message {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 20px;
  }  

  /* Search container */
.searchContainer {
  display: flex; /* Flexbox for horizontal alignment */
  align-items: center; /* Align items vertically in the center */
  border: 1px solid #ccc; /* Border around the container */
  border-radius: 5px; /* Rounded corners for the container */
  width: 400px; /* Adjust the width of the whole search bar */
  height: 40px; /* Adjust the height of the entire container */
  margin-left: auto; /* Center the container horizontally and add some space below */
  padding: 0; /* Remove any padding inside the container */
  overflow: hidden; /* Ensure the button stays inside the container */
}

/* Search input field */
.input {
  flex: 1; /* Allow the input to take up remaining space */
  border: none; /* Remove the input border */
  padding: 10px; /* Add padding for the input */
  height: 100%; /* Make the input fill the height of the container */
  outline: none; /* Remove the outline on focus */
  border-radius: 5px 0 0 5px; /* Rounded corners on the left */
}

/* Search button */
.searchButton {
  background-color: #DAA520; /* Button background color */
  border: none; /* Remove the border */
  color: white; /* White color for the icon/text */
  padding: 0 20px; /* Add horizontal padding for the button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
  display: flex; /* Flexbox to center content inside the button */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  height: 40px; /* Make the button the same height as the input */
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
}

/* Hover state for the button */
.searchButton:hover {
  background-color: #c3941d; /* Darker background color on hover */
}

/* Remove focus outline on button */
.searchButton:focus {
  outline: none; /* Remove default focus outline */
}
