.payment-section {
  padding: 20px;
  max-width: 800px;
  margin: 80px auto 40px;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.payment-section h2 {
  font-size: 2rem;
  color: #D32F2F;
  margin-bottom: 25px;
}

.payment-section h3 {
  font-size: 1.6rem;
  color: #444;
  margin-bottom: 35px;
}

.step {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.step:hover {
  background-color: #f1f1f1;
  transform: translateY(-2px);
}

.step h4 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.step h4::before {
  content: '✔️';
  margin-right: 10px;
  font-size: 1.2rem;
}

.step p {
  font-size: 16px;
  line-height: 1.8;
  color: #555;
}

.divider {
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

.bankDetails h5 {
  margin-bottom: 0.5rem;
}

.bankDetails ul {
  padding: 0;
}

.bankDetails li {
  margin-bottom: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .payment-section h2 {
    font-size: 2rem;
  }

  .payment-section h3 {
    font-size: 1.4rem;
  }

  .step h4 {
    font-size: 1.3rem;
  }

  .step p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .payment-section h2 {
    font-size: 1.7rem;
  }

  .payment-section h3 {
    font-size: 1.3rem;
  }

  .step h4 {
    font-size: 1.2rem;
  }

  .step p {
    font-size: 0.95rem;
  }
}
