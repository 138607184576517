/* carroCompras.module.css */
.body {
    min-height: 100vh;
}

.container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 100px auto;
}

h2 {
    text-align: center;
    font-size: 2rem;
    color: #D32F2F;
    margin-bottom: 20px;
    font-weight: bold;
}

.item {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center; 
    justify-content: center; 
}

.cartItem {
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 350px;
    max-width: 100%; /* Ensures it is responsive */
    box-sizing: border-box;
    flex-wrap: wrap; /* Ensures elements wrap properly */
}

.closeButton {
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 10px;
}

.closeButton:hover {
    color: white;
    background-color: #DAA520;
}

.emoticon {
    width: 40px;
    height: auto;
}

.cartDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.cartDetails p {
    margin: 0;
    font-size: 16px;
    color: #495057;
    text-align: left;
}

.cartDetails p:first-child {
    font-weight: bold;
}

.total {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #dee2e6;
    font-size: 1.1rem;
    color: #495057;
    text-align: center;
}

.total p {
    margin: 5px 0;
}

.totalCost {
    font-size: 1.1rem;
    font-weight: bold;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttons button {
    padding: 10px 20px;
    background-color: #D32F2F;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttons button:hover {
    background-color: #B71C1C;
}

.buttons2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttons2 button {
    padding: 10px 20px;
    background-color: #DAA520;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttons2 button:hover {
    background-color: #b88b1c;
}

.messageBox {
    bottom: 20px;
    right: 20px;
    background-color: #d7f8de;
    color: #1c7237;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.modalButton {
    padding: 10px 20px;
    background-color: #D32F2F; /* Color de fondo */
    color: white; /* Color del texto */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.modalButton:hover {
    background-color: #B71C1C; /* Color al pasar el ratón */
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .container {
        padding: 15px;
        max-width: 100%;
    }

    h2 {
        font-size: 1.8rem;
    }

    .cartItem {
        padding: 10px;
        width: 100%; /* Full width on smaller screens */
        gap: 10px;
        flex-direction: row;
    }

    .cartDetails p {
        font-size: 14px;
    }

    .emoticon {
        width: 30px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
    }

    h2 {
        font-size: 1.5rem;
    }

    .cartItem {
        padding: 8px;
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the left */
    }

    .cartDetails p {
        font-size: 12px;
    }

    .emoticon {
        width: 25px;
    }

    .closeButton {
        width: 15px;
        height: 15px;
        font-size: 8px;
    }

    .buttons button, .buttons2 button {
        padding: 8px 16px;
        font-size: 14px;
    }
}
