.body {
  min-height: 100vh;
}
.contact-section {
  padding: 30px;
  max-width: 900px;
  margin: 80px auto 30px;
  background-color: #f4f4f4;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.contact-section h2 {
  font-size: 2.2rem;
  color: #D32F2F;
  margin-bottom: 25px;
  text-align: center;
}

.contact-section h3 {
  font-size: 1.6rem;
  color: #444;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 16px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 25px;
  text-align: justify;
}

.social-media {
  margin-top: 30px;
}

.social-media p {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.icon {
  margin-right: 15px;
  color: #D32F2F;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
  color: #b12a2a;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-section {
    padding: 20px;
  }

  .contact-section h2 {
    font-size: 2rem;
  }

  .contact-section h3 {
    font-size: 1.4rem;
  }

  .contact-section p {
    font-size: 1rem;
  }

  .social-media p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .contact-section h2 {
    font-size: 1.7rem;
  }

  .contact-section h3 {
    font-size: 1.3rem;
  }

  .contact-section p {
    font-size: 0.95rem;
  }

  .social-media p {
    font-size: 1rem;
  }
}