/* General Styles for Authentication Components */
.body {
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 150px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

form {
  width: 100%;
  text-align: center;
}

input {
  width: calc(100% - 20px); /* Ajusta el ancho del input para dejar espacio para el botón */
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  position: relative; /* Asegura que el contenedor del input se use como referencia para el botón del ojo */
}

.passwordContainer {
  position: relative; /* Asegura que el botón del ojo se posicione relativo a este contenedor */
}

.eyeButton {
  position: absolute;
  right: 10px; /* Espacio desde el borde derecho del input */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1; /* Asegura que el botón se muestre sobre el input */
  top: 50%; /* Centra verticalmente */
  transform: translateY(-50%); 
}

.eyeButton:hover {
  color: white; /* Cambia el color del ícono al pasar el ratón */
  transform: none; /* Elimina el transform para que vuelva a la posición original */
  top: 0;
}

button {
  width: auto;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin: 10px 0;
}

button:hover {
  background-color: #0056b3;
}

.auth-link {
  margin-top: 10px;
  font-size: 14px;
}

.auth-link a {
  color: #007bff;
  text-decoration: none;
}

.auth-link a:hover {
  text-decoration: underline;
}

/* Logout Button Styles */
.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #c82333;
}

.errorMessage {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb; /* Light red border */
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}