.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes full viewport height */
}

.main-content {
  flex: 1; /* Allows the main content to take up remaining space */
  padding: 100px; /* Optional: Add padding for content */
}

footer {
  margin-top: auto;
  background-color: #f1f1f1;
  text-align: center;
  box-sizing: border-box;
  bottom: 0;
}

/* Add to your global CSS */
body {
  margin: 0;
  padding: 0;
}