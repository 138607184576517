.faq-section {
  margin: 0 auto; /* Center the section */
  padding: 20px; /* Add some padding for spacing */
  max-width: 800px; /* Limit the width for larger screens */
  margin-top: 70px; /* Margen superior */
  text-align: center; /* Center the heading */
  min-height: 100vh;
}

.faq-section h2{
  color: #D32F2F;
  font-size: 2rem;
}

.faq-section div{
  text-align: left;
}

.faq-section .link{
  color: #D32F2F;
  font-size: 16px;
}

.faq-section .accordion-button {
  background-color: white; /* Default background color */
  color: #D32F2F; /* Default text color */
}

.faq-section .accordion-button:hover {
  background-color: #D32F2F; /* Hover background color */
  color: white; /* Hover text color */
}

.faq-section .accordion-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5); /* Optional: focus ring */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-section {
    padding: 10px; /* Reduce padding for smaller screens */
    max-width: 100%; /* Make it full-width on smaller screens */
  }
}

@media (max-width: 480px) {
  .faq-section h2 {
      font-size: 1.8rem;
  }
}