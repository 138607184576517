/* Global reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  font-size: 15px; /* Adjusted for smaller font size */
  transition: color 0.3s ease;
}

/* Añadir estilos para la sección de inicio de sesión / registro */
.userActions {
  display: flex;
  gap: 20px;
}

/* Navbar */
.navbar {
  background-color: white !important;
  color: black !important;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 0.5px solid #000;
  transition: background-color 0.3s ease;
}


/* Tabs aligned to the left */
.navMenu {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 0; /* Align to left */
}

/* Logo aligned to the center */
.logoContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1; /* Centers the logo */
}

.logoImage {
  width: 100px;
  height: 50px;
}

/* User actions aligned to the right */
.userActions {
  margin-left: auto; /* Align to the right */
  display: flex;
  gap: 20px;
}

/* Content margin to avoid overlap */
body {
  margin-top: 100px; /* Adjust this value to the height of the navbar */
}

/* Hover effect for individual navbar links */
.navbar a {
  text-decoration: none; /* Ensure no underline */
  color: black; /* Ensure text color is black */
}

.navbar a:hover {
  color: rgb(202, 18, 18); /* Change color on hover */
}

/* Media Queries */
@media screen and (max-width: 1000px) {
  /* Adjust font size for smaller screens */
  .navbar a {
    font-size: 13px;
  }
}

@media screen and (max-width: 870px) {
  /* Adjust font size for smaller screens */
  .navbar a {
    font-size: 11px;
  }
}

@media screen and (max-width: 870px) {
  /* Adjust font size for smaller screens */
  .navbar a {
    font-size: 10px;
  }
}

@media screen and (max-width: 780px) {
  /* Hide the navbar menu by default on small screens */
  .navMenu {
    display: none;
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px; /* Adjust this value to the height of the navbar */
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Optional border for the dropdown */
    font-size: 10px;
  }

  /* Show the navbar menu when the hamburger is active */
  .navMenu.active {
    display: flex;
    left: 0;
  }

  /* Hamburger icon */
  .hamburger {
    margin-left: 20px;
    display: block;
    cursor: pointer;
  }

  .bar {
    background-color: black;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    transition: 0.3s ease-in-out;
  }

  /* Active state of the hamburger menu */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

/* Ensure the navbar menu is displayed normally on larger screens */
@media screen and (min-width: 781px) {
  .navMenu {
    display: flex;
    position: static;
    flex-direction: row;
    gap: 20px;
    margin: 0;
    top: auto;
    left: auto;
    background-color: transparent;
  }
}