/* Contenedor principal del perfil */
.container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin: 120px auto;
  background-color: #ffffff;
  text-align: left !important; /* Alineación a la izquierda */
  justify-content: left;
  align-items: left;
  font-family: 'Arial', sans-serif;
}

/* Estilo del encabezado del perfil */
.profileHeader {
  border-bottom: 1px solid #D32F2F;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.profileHeader h2 {
  margin: 0;
  color: #D32F2F;
  text-align: left;
  padding-left: 20px;
  font-family: 'Arial', sans-serif;
}

/* Información del perfil */
.profileInfo {
  margin-bottom: 30px;
  padding-left: 20px; /* Agregar padding para espaciar */
  position: relative; /* Para posicionar el icono */
}

.profileInfo p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

/* Apartado reservaciones tickets */
.reservacionesHeader {
  border-bottom: 1px solid #D32F2F;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.reservaciones h2 {
  margin: 0;
  color: #D32F2F;
  text-align: left;
  padding-left: 20px;
  font-family: 'Arial', sans-serif;
}

/* Contenedor de cada folio */
.folioContainer {
  margin-bottom: 20px;
  padding: 15px; /* Más padding para que se vea separado */
  border-bottom: 1px solid #333;
}

.folioContainer h4 {
  margin-top: 0;
  color: #333;
}

/* Lista de tickets */
.ticketsList {
  list-style: none;
  margin: 0;
  width: 700px;
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan */
  padding: 0; /* Eliminar padding */
  margin: 0; 
}

.ticketsList li {
  flex: 1 1 1 170px; /* Permite que los tickets ocupen espacio, el tamaño mínimo será de 200px */
  margin: 5px; /* Espaciado entre tickets */
  padding: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ticketsList p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.noTicketsMessage {
  padding-left: 20px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .profileInfo p {
    font-size: 14px;
  }

  .ticketsList li {
    padding: 8px;
  }
}