.buy-section {
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 70px auto 0;
}

.buy-section h2 {
  font-size: 2rem;
  color: #D32F2F;
  margin-bottom: 20px;
}

.buy-section p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 30px;
}

.buy-section h3 {
  font-size: 1.8rem;
  color: #D32F2F;
  margin-bottom: 15px;
}

/* Selected Tickets Section */
.selectedTicketsContainer {
  margin-top: 0px;
  height: auto;          /* Auto height based on content */
  max-height: 250px;     /* Maximum height */
  overflow-y: auto;      /* Scroll if content exceeds max-height */
  max-width: 800px;
  margin-bottom: 30px;
  display: block;        /* Ensure container is displayed by default */
}

/* Hide the container when it's empty */
.selectedTicketsContainer:empty {
  display: none; /* Hide container when no tickets are selected */
}

/* Styling for the heading of the selected tickets */
.selectedTickets h3 {
  background-color: white;
  font-size: 1.4rem;
  color: #D32F2F;
  margin-bottom: 10px;
  text-align: left;
  margin-top: 0px;
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Styling for the number of selected tickets */
.selectedTickets h3 span {
  color: #333;
}

/* List styling for the selected tickets */
.selectedTickets ul {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px; /* Space between items */
  padding: 0;
  margin: 0;
}

/* List item styling */
.selectedTickets ul li {
  position: relative;
  font-size: 0.9rem;
  line-height: 1.2;
}

/* Hide empty container when no tickets are selected */
.emptyTicketsContainer {
  display: none; /* Ensure it's hidden when no tickets */
}

/* Lucky button section */
.buttonsReserve {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttonsReserve button {
    padding: 10px 20px;
    background-color: #DAA520;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonsReserve button:hover {
    background-color: #b88b1c !important;
}

.buttonsInv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttonsInv button {
    padding: 10px 20px;
    background-color: #D32F2F;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.luckySection button {
  height: 40px; /* Same height as the search input */
  background-color: #D32F2F; /* Red background color */
  color: white;
  padding: 0 15px; /* Button padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-content: center;
  margin-top: 0px !important;
  margin-bottom: 20px;
  margin-left: 45px;
}

.buy-section button:hover, .luckySection button:hover {
  background-color: #a00;
}

.searchAndLuckyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

/* Estilos para el mensaje de éxito */
.luckyMessage {
  margin: 20px auto;
  text-align: center;
  padding: 15px;
  font-size: 16px;
}

.luckyMessage h3 {
  font-size: 1.8rem;
  color: #D32F2F;
  margin-bottom: 15px;
}

.luckyMessage p {
  font-size: 1em;
  line-height: 1.6;
  padding: 20px;
}

/* Lucky Section */
/* Lucky modal styles */
.modalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modalRow p {
  margin-right: 10px;
  font-size: 16px;
  margin-top: 25px;
}

.modalRow input {
  margin-right: 10px;
  font-size: 16px;
  width: 150px;
}

.modalCloseButton:hover {
  background-color: #a00;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .buy-section h2 {
      font-size: 2rem;
  }

  .buy-section h3 {
      font-size: 1.6rem;
  }

  .buy-section button, .luckySection button {
      font-size: 1.1rem;
      padding: 0px 25px;
      width: 60%;
  }

  .searchAndLuckyContainer {
    flex-direction: column; /* Cambia la dirección del contenedor a vertical */
    align-items: center; /* Alinea los elementos al principio */
  }

  .searchContainer {
    width: 100%; /* Asegúrate de que el input ocupe todo el ancho disponible */
    margin-top: 10px; /* Añade un margen superior para separar el buscador del botón */
  }

  .luckySection {
    width: 100%; /* El botón también ocupará todo el ancho disponible en pantallas pequeñas */
    margin-top: 10px;
  }

  .luckySection button {
    margin-left: 0; /* Elimina el margen izquierdo para el lucky button en pantallas pequeñas */
  }

  .modalRow {
    margin-top: 40px;
  }

  .modalRow p {
    font-size: 16px;
  }

  .modalRow input {
    width: 30%; /* Make the input wider on smaller screens */
  }

  .luckySlotButton {
    font-size: 0.8rem;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .buy-section h2 {
      font-size: 1.8rem;
  }

  .buy-section h3 {
      font-size: 1.5rem;
  }

  .buy-section button, .luckySection button {
      font-size: 14px;
      padding: 12px 20px;
      width: 100%;
  }

  .luckySection {
    align-content: center;
  }

  .luckySection button {
    margin-left: 0; /* Elimina el margen izquierdo para el lucky button */
  }

  .modalRow {
    margin-top: 40px;
  }

  .modalRow p {
    font-size: 14px;
  }

  .modalRow input {
    width: 30%;
  }

  .luckySlotButton {
    font-size: 0.5rem !important;
  }

  .buttonsReserve button, .buttonsInv button {
      padding: 8px 16px;
      font-size: 14px;
  }

  .luckyGifContainer {
    width: 300px;
    align-content: center;
  }

  .luckyGif {
    width: 300px;
  }
}
