.image-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 250px); /* Fixed height for each row */
    gap: 10px;
    padding: 20px;
    justify-items: center; /* Center items horizontally */
    align-items: center; 
}

.first-image, .second-image, .fourth-image, .fifth-image, .sixth-image, .seventh-image {
    width: 100%;
    max-height: 200px; /* Maximum height */
    height: 100%; /* Fill the height of the grid cell */
    object-fit: cover; /* Maintain aspect ratio */
}

.third-image {
    grid-column: 3 / 4; /* Span across columns 2 and 3 */
    grid-row: 1 / 3; /* Span across both rows */
    width: 100%;
    max-height: 500px; /* This will ensure the image can fill the height */
    height: auto; /* Auto to let it respect its aspect ratio */
    object-fit: cover; /* Maintain aspect ratio */
}

.first-image {
    grid-column: 1;
    grid-row: 1 / 3;
}

.second-image {
    grid-column: 2;
    grid-row: 1;
    align-self: end;
}

.fourth-image {
    grid-column: 2;
    grid-row: 2;
    align-self: start;
}

.fifth-image {
    grid-column: 4;
    grid-row: 1;
    align-self: end; 
}

.sixth-image {
    grid-column: 4;
    grid-row: 2;
    align-self: start; 
}

.seventh-image {
    grid-column: 5;
    grid-row: 1 / 3;
}