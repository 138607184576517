.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  display: none; /* Inicialmente no se muestra */
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  backdrop-filter: blur(5px); 
  transition: all 0.3s ease-in-out;
}

.modalOverlay.active {
  display: flex; /* Se mostrará solo cuando tenga la clase 'active' */
}

.modalContent {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  position: relative;
  max-width: 600px;
  width: 100%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); 
  transform: scale(0.95);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.modalOverlay.active .modalContent {
  transform: scale(1);
  opacity: 1;
}

.modalBody {
  margin-top: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333; 
  line-height: 1.5;
  text-align: center; 
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #ff5f5f; 
  color: white;
  border: none;
  font-size: 12px !important;
  cursor: pointer;
  border-radius: 50% !important;
  padding: 6px 12px !important;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.closeButton:hover {
  background-color: #ff3b3b; 
}

/* Media Queries */
@media (max-width: 768px) {
  .modalContent {
    padding: 20px;
    max-width: 90%;
  }

  .modalBody {
    font-size: 14px;
    line-height: 1.4;
  }

  /* Close button for smaller screens */
  .closeButton {
    font-size: 12px !important;
    padding: 5px 10px !important;
    width: auto !important;
  }
}

@media (max-width: 480px) {
  .modalContent {
    padding: 15px;
    max-width: 90%;
  }

  .modalBody {
    font-size: 14px;
    line-height: 1.4;
  }

  /* Close button for extra small screens */
  .closeButton {
    font-size: 12px !important;
    padding: 5px 10px !important;
    width: auto !important;
  }
}