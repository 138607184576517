/* Sección de inicio */
.inicio-section {
  text-align: center;
  margin-top: 70px; /* Margen superior */
}

/* Sección Intro con imagen de fondo */
.intro {
  width: 100%;
  height: 400px;
  background: url('../../assets/miniInicio.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  color: #ffffff;
  text-align: center;
  position: relative; /* Position relative to use the pseudo-element for shadow */
  overflow: hidden; /* Ensure no overflow from the pseudo-element */
}

/* Pseudo-elemento para la sombra */
.intro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8); /* Shadow effect */
  z-index: 1; /* Ensure the shadow is above the image but below the text */
  pointer-events: none;
}

/* Ensure text is above the pseudo-element */
.intro h1, .intro p {
  position: relative; /* Make sure text is above the shadow */
  z-index: 2;
}

.intro h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: white;
}

.intro p {
  font-size: 1.2em;
  line-height: 1.6;
}

.buttonContainerIntro {
  position: relative; /* Ensure it can use z-index */
  z-index: 2; /* Make sure it's above the shadow */
  margin-top: 50px; 
}

.btn-reserva {
  background-color: #D32F2F;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2;
  margin-bottom: 50px;
}

.btn-reserva:hover {
  background-color: #b22424;
}

.descripcion {
  margin: 0 auto; /* Centra la sección horizontalmente */
  background-color: #ffffff;
  padding: 60px;
  min-height: 660px; /* Asegura que la sección tenga una altura mínima */
  display: flex; /* Usar flexbox */
  align-items: center; /* Centra verticalmente el contenido */
  justify-content: center; /* Centra horizontalmente el contenido */
}

.descripcionContent {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  flex-direction: row; /* Mantén las columnas en fila */
  gap: 10px;
  height: 100%; /* Asegura que la sección ocupe toda la altura disponible */
}

.texto {
  flex: 1;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; /* Asegura que el texto ocupe toda la altura del contenedor */
  margin-left: 70px;
}

.texto h2 {
  font-size: 2.5em;
}

.grafico {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
}


/* Sección de fecha */
.fecha {
  padding: 30px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #D32F2F;
  color: white;
}

.countdown {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.countdownUnit {
  width: 100px;
  height: auto;
  display: inline-block;
  margin: 0 10px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

.countdownUnit h2 {
  font-size: 2rem;
  margin: 0;
  color: white;
}

.countdownUnit span {
  font-size: 1rem;
}

.premiosGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Ajusta según el ancho mínimo y columnas automáticas */
  gap: 1rem; /* Espaciado entre columnas */
  margin-top: 1rem;
}

/* Sección de premio */
.premio {
  background-color: #fff;
  padding: 30px;
  padding-bottom: 0px;
}

.premio h2 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Premio Content */
.premioContent {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}

.column {
  flex: 1;
  padding: 0 15px;
  text-align: left;
  font-size: 1.1em;
  line-height: 1.4;
}

/* Specifications List */
.caracteristicas li {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5;
}

.caracteristicas li::before {
  content: '•';
  margin-right: 8px;
  color: #D32F2F;
}
.image-gallery {
  display: flex;
  flex-wrap: wrap; /* Allows images to wrap to the next line on smaller screens */
  justify-content: space-between; /* Distributes space evenly between images */
}

.image-gallery img {
  width: 20%; /* Set each image to take up 20% of the container width */
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits within its container */
  margin: 0; /* Remove any margin around the images */
  padding: 0; /* Remove any padding around the images */
  border: 0; /* Remove any border around the images */
}

/* Sección de boletos */
.boletos {
  padding: 0px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 50px;
  max-height: 400px; /* Altura máxima para la sección de boletos */
  overflow-y: auto;
}

.boletosDisponibles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.boleto {
  background-color: #DAA520;
  padding: 10px;
  border-radius: 8px;
  width: 60px; /* Ajuste del ancho */
  height: auto; /* Auto ajustar la altura */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  margin: 10px;
}

.boleto:hover {
  background-color: #b88b1c;
}

.boleto.selected {
  background-color: #D32F2F; /* or any color to highlight selected tickets */
}

/* Bonos.css */
.bonos {
  background-color: #D32F2F;
  padding: 20px;
  color: white;
}

.bonos-title {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.bonos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.bono {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 5px;
}

.bono p {
  text-align: left;
}

/* Search container */
.searchContainer {
  display: flex; /* Flexbox for horizontal alignment */
  align-items: center; /* Align items vertically in the center */
  border: 1px solid #ccc; /* Border around the container */
  border-radius: 5px; /* Rounded corners for the container */
  width: 200px; /* Adjust the width of the whole search bar */
  height: 40px; /* Adjust the height of the entire container */
  /* margin: 0 auto 10px;  Center the container horizontally and add some space below */
  padding: 0; /* Remove any padding inside the container */
  overflow: hidden; /* Ensure the button stays inside the container */
  justify-content: flex-end !important;
  margin-left: auto;
  margin-right: 45px;
  margin-bottom: 20px;
}

/* Search input field */
.input {
  flex: 1; /* Allow the input to take up remaining space */
  border: none; /* Remove the input border */
  padding: 10px; /* Add padding for the input */
  height: 100%; /* Make the input fill the height of the container */
  outline: none; /* Remove the outline on focus */
  border-radius: 5px 0 0 5px; /* Rounded corners on the left */
}

/* Search button */
.searchButton {
  background-color: #DAA520 !important; /* Button background color */
  border: none; /* Remove the border */
  color: white; /* White color for the icon/text */
  padding: 0 20px; /* Add horizontal padding for the button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
  display: flex; /* Flexbox to center content inside the button */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  height: 40px; /* Make the button the same height as the input */
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
}

/* Hover state for the button */
.searchButton:hover {
  background-color: #9a7517; /* Darker background color on hover */
}

/* Remove focus outline on button */
.searchButton:focus {
  outline: none; /* Remove default focus outline */
}

/* Sección de proceso */
.proceso {
  background-color: #fff;
  padding-top: 0px;
  padding: 40px;
  border-radius: 8px;
  text-align: left;
}

/* Contenedor general para la sección de precios */
.precio {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Espacio entre las cajas */
  justify-content: center; /* Centrar las cajas */
  padding: 20px;
  border-radius: 8px;
}

/* Container for the precio box */
.precioBox {
  position: relative; /* Ensure relative positioning for flag placement */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: left;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

/* First h3 - noticeable (larger size, bolder) */
.precioBox h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  margin-bottom: 10px;
}

.precioBox p:first-of-type {
  font-size: 1.2rem;
  color: #666;
  font-weight: normal;
  margin-bottom: 10px;
}

.precioBox p:nth-of-type(2) {
  font-size: 1.2rem;
  color: #e74c3c; 
  font-weight: bold;
}

/* Discount flag (styled box to the right within the box) */
.discount-flag {
  position: relative;
  right: 10px; /* Adjusted to stay within the box */
  padding: 5px 10px;
  background-color: #e74c3c;
  width: 60px;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;
}

.selectedTicketsContainer {
  height: auto;          /* Auto height based on content */
  max-height: 250px;     /* Maximum height */
  overflow-y: auto;      /* Scroll if content exceeds max-height */
  max-width: 800px;
  margin: 0 auto 30px;   
  display: block;   

}

/* Styling for the heading of the selected tickets */
.selectedTickets h3 {
  background-color: white;
  font-size: 1.4rem;
  color: #D32F2F;
  margin-bottom: 10px;
  text-align: left;
  margin-top: 0px;
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Hide the container when it's empty */
.selectedTicketsContainer:empty {
  display: none; /* Hide container when no tickets are selected */
}

/* Styling for the number of selected tickets */
.selectedTickets h3 span {
  color: #333;
}

/* List styling for the selected tickets */
.selectedTickets ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Two columns */
  gap: 20px; /* Space between items */
  padding: 0;
  margin: 0;
}

/* List item styling */
.selectedTickets ul li {
  position: relative;
  font-size: 0.9rem;
  line-height: 1.2;
}

/* Hide empty container when no tickets are selected */
.emptyTicketsContainer {
  display: none; /* Ensure it's hidden when no tickets */
}

 /* Container for better structure */
 .luckySlotContainer {
  margin-left: 45px;
  margin-bottom: 20px;
}

/* Lucky button styling */
.luckySlotButton, .luckySlotContainer button {
  height: 40px; /* Same height as the search input */
  background-color: #D32F2F !important; /* Red background color */
  color: white;
  padding: 0 15px; /* Button padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-content: center;
}

/* Hover effect */
.luckySlotButton:hover, .luckySlotContainer button:hover {
  background-color: #a00 !important;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttons button {
    padding: 10px 20px;
    background-color: #D32F2F;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttons button:hover {
    background-color: #B71C1C;
}

.buttons2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.buttons2 button {
    padding: 10px 20px;
    background-color: #DAA520;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttons2 button:hover {
    background-color: #b88b1c;
}

/* Estilos responsivos */
@media (max-width: 810px) {
  .intro h1 {
    font-size: 2em;
  }

  .intro p {
    font-size: 1em;
  }

  .descripcion {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .descripcionContent {
    flex-direction: column;
  }

  .texto {
    margin-right: 0;
    margin-left: 20px;
  }

  .texto h2 {
    font-size: 2em;
  }

  .grafico {
    width: 40%;
  }

  .fecha {
    margin-top: 50px;
  }

  .premioContent {
    flex-direction: column;
  }

  .column {
    padding: 0;
    margin-bottom: 20px;
  }

  .boletos {
    padding: 20px;
    max-height: 300px; /* Reducir la altura máxima en pantallas medianas */
  }

  .boleto {
    width: 50px; /* Ajustar el ancho de los boletos */
    height: auto;
  }

  .precioBox {
    min-width: 40%; /* Ajusta para ocupar el 100% del ancho en pantallas pequeñas */
    max-width: 100%; /* Ajusta el tamaño máximo en pantallas pequeñas */
    margin: 0; /* Elimina el margen para ajustarse mejor en pantallas pequeñas */
  }

  .precio {
    gap: 10px; /* Reduce el espacio entre las cajas en pantallas pequeñas */
    padding: 10px; /* Reduce el padding en pantallas pequeñas */
  }

  .precioBox h3 {
    font-size: 1.125rem; /* Ajustar el tamaño de la fuente para pantallas pequeñas */
  }

  .precioBox p:first-of-type {
    font-size: 0.9rem; /* Ajustar el tamaño de la fuente para pantallas pequeñas */
  }

  .precioBox p:nth-of-type(2) {
    font-size: 0.9rem; /* Ajustar el tamaño de la fuente para pantallas pequeñas */
  }

  .bono p {
    font-size: 0.9rem;
  }

  .discount-flag {
    font-size: 0.75rem; /* Ajustar el tamaño de la fuente para pantallas pequeñas */
    margin-left: 5px;
    width: 45px;
  }

  .cta-button {
    padding: 12px 24px;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  /* Centrar el search bar en pantallas pequeñas */
  .searchContainer {
    width: 60%; /* Asegura que el contenedor ocupe el 100% del ancho */
    margin-left: auto;
    margin-right: auto;
    justify-content: center; /* Centra el contenido dentro del contenedor */
  }

  .input {
    width: 80%; /* Ajusta el ancho del input dentro del contenedor */
  }

  .searchButton {
    width: auto !important; /* Permite que el botón ajuste su tamaño */
  }
}

@media (max-width: 480px) {
  .intro h1 {
    font-size: 1.5em;
  }

  .intro p {
    font-size: 0.9em;
  }

  .descripcion {
    margin-bottom: 30px;
  }

  .descripcionContent {
    flex-direction: column;
  }

  .texto {
    margin-right: 0;
    margin-bottom: 40px;
    padding: 20px; 
  }

  .texto h2 {
    font-size: 1.5em;
  }

  .grafico {
    display: none;
  }

  .fecha {
    margin-top: 40px;
  }

  .premio {
    padding: 20px;
  }

  .premio h2 {
    font-size: 1.8em;
  }

  .caracteristicas li {
    font-size: 0.95em;
  }

  .premioContent {
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin-bottom: 20px;
  }

  .boletos {
    padding: 5px;
    max-height: 300px; /* Reducir la altura máxima en pantallas pequeñas */
  }

  .boleto {
    width: 48px; /* Ajustar el ancho de los boletos */
    padding: 7px;
    font-size: 13px;
    height: auto;
  }

  .precioBox {
    padding: 10px; /* Reducir padding en pantallas más pequeñas */
    margin-bottom: 10px; /* Reducir el margen en pantallas más pequeñas */
  }

  .precioBox h3 {
    font-size: 1rem; /* Ajustar el tamaño de la fuente para pantallas muy pequeñas */
  }

  .precioBox p:first-of-type {
    font-size: 0.875rem; /* Ajustar el tamaño de la fuente para pantallas muy pequeñas */
  }

  .precioBox p:nth-of-type(2) {
    font-size: 0.875rem; /* Ajustar el tamaño de la fuente para pantallas muy pequeñas */
  }

  .discount-flag {
    font-size: 0.625rem; /* Ajustar el tamaño de la fuente para pantallas muy pequeñas */
  }

  .bono p {
    font-size: 0.875rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .searchContainer {
    display: flex;
    justify-content: center; /* Centra la barra de búsqueda */
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
  }

  .input {
    width: 80%; /* Asegura que el input no sea demasiado ancho */
    margin-right: 10px; /* Espacio entre el input y el botón */
  }

  .searchButton {
    width: 20%; /* El botón ocupa el resto del espacio */
  }

  .buttons button, .buttons2 button {
    padding: 8px 16px;
    font-size: 14px;
    width: 100%;
  }
}
